import Image from 'next/image'
import { useTheme } from '@emotion/react'

import { Link } from '@/lib/link'

export function Address() {
  const theme = useTheme()

  return (
    <section
      css={theme.mq({
        textAlign: { base: 'center', sm: 'left' },
        fontWeight: '--fontWeights-light',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: { base: 'center', sm: 'flex-start' },
        position: 'relative',
        fontSize: {
          base: '--fontSizes-md',
          md: '--fontSizes-xl',
        },
      })}
    >
      <figure
        css={theme.mq({
          display: {
            base: 'block',
            md: 'none',
          },
        })}
      >
        <Image
          src="/images/logo-alternate.svg"
          alt="iso"
          width="356px"
          height="55px"
        />
      </figure>
      <p
        css={theme.mq({
          display: {
            base: 'none',
            md: 'block',
          },
          color: '--colors-primary-250',
          fontWeight: '--fontWeights-medium',
        })}
      >
        ติดต่อเรา
      </p>
      <p
        css={theme.mq({
          marginTop: {
            base: '--space-4',
            sm: '--space-6',
          },
        })}
      >
        บริษัท เอ็นเดต้าธอธ จำกัด <br />7 อาคารซัมเมอร์ พอยท์ ชั้นที่ 2
        ซอยสุขุมวิท 69 แขวงพระโขนงเหนือ เขตวัฒนา กรุงเทพมหานคร 10110 ประเทศไทย
      </p>
      <Link to="TEL">
        <a
          css={theme.mq({
            ...theme.layerStyles.actionable,
            display: 'block',
            color: '--colors-secondary-500',
            fontSize: '--fontSizes-3_25xl',
            marginTop: {
              base: '--space-6',
              sm: '--space-10',
            },
          })}
        >
          โทร: 02-024-5560
        </a>
      </Link>
      <Link to="EMAIL">
        <a
          css={theme.mq({
            ...theme.layerStyles.actionable,
            display: 'block',
            fontSize: {
              base: '--fontSizes-2xl',
              sm: '--fontSizes-3xl',
            },
          })}
        >
          sales@ndatathoth.com
        </a>
      </Link>
      <iframe
        src="https://maps.google.com/maps?q=NDATATHOTH%20COMPANY%20LIMITED&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
        title="ndatathoth-map"
        className="map"
        css={theme.mq({
          width: '--sizes-full',
          marginTop: '--space-10',
          height: {
            base: '150px',
            sm: '200px',
          },
          display: {
            base: 'block',
            md: 'none',
          },
        })}
      ></iframe>
    </section>
  )
}
